<template>
    <p-dialog class="small-dialog" :showHeader="false" :closable="false" :visible.sync="visible" @hide="onClose"
        :modal="true">
        <div class="ui center aligned">
            <br />
            <h4 class="ui">
                You Free Plan is about to expire soon, Please subscribe now...

            </h4>
            <br />
            <div class="ui red" v-if="showError" style="color: red">
                {{ msg }}
            </div>
        </div>
        <template #footer>
            <p-button label="Subscribe" @click="onSave" />
            <p-button label="Close" @click="onClose" class="p-button-secondary" />
        </template>
    </p-dialog>
</template>

<script>
import { mapMutations } from 'vuex';
import { subscribeToPackage } from '../../../services/user';
import config from '../../../config';
export default {
    name: 'SubscriptionExpireModal',
    components: {
    },
    props: {
        visible: {
            type: Boolean,
            default: false,
        },

    },
    data() {
        return {
            monthly_priceId: config.Monthly_priceId,
            msg: '',
            showError: false,
        }
    },

    methods: {
        ...mapMutations(['SET_SUB_EXP_POPUP']),
        async onSave() {
            const selectedPlan = this.$store.state.user.selectedSubsciption.toLowerCase()
            if (selectedPlan && selectedPlan) {
                const url = await subscribeToPackage(selectedPlan)
                if (url && url.error) {
                    this.showError = true
                    this.msg = url.message
                    setTimeout(() => {
                        this.showError = false
                        this.SET_SUB_EXP_POPUP(false)
                    }, 5000)
                } else {
                    window.location = url.data
                }
            } else {
                this.showError = true
                this.msg = 'Something went wrong! please try again.'
                setTimeout(() => {
                    this.showError = false
                    this.SET_SUB_EXP_POPUP(false)

                }, 3000)
            }
        },
        onClose() {
            this.SET_SUB_EXP_POPUP(false)
        }
    },
}

</script>
<style></style>