<template>
    <p-dialog :visible.sync="visible" :closable="false" @hide="onClose" :modal="true" class="indicator-modal">
        <template #header>
            <div class="custom-header-container">
                <div class="custom-header-box-start">

                    <h2>ProTrader Search </h2>
                    <div class="custom-header-input-container">
                        <p-inputText placeholder="Search Indicators..." icon="" fluid v-model="filteredtext"
                            v-on:keyup="keyUpSearch" class="p-inputtext-lg" />
                    </div>
                </div>
                <div class="custom-header-box-end">
                    <p-button icon="pi pi-refresh" class="p-button-secondary p-button-outlined" @click="onReset"
                        v-tooltip.bottom="`Reset`" />
                    <p-button label="Confirm" @click="handleButtonClick(OnConfirm)" />
                    <p-button icon="pi pi-times" class="p-button-secondary p-button-outlined" @click="onClose"
                        v-tooltip.bottom="`Cancel`" />
                </div>
            </div>

        </template>
        <div class="p-d-flex">
            <!-- Exchange List -->
            <div style="flex: 0.3;">
                <p-accordion :activeIndex="0" class="indicator-accordian">

                    <p-accordiontab :header="overlay?.title" v-for="(overlay, index) in filterOverlayList" :key="index">

                        <p-listbox v-model="selectedOverlay" :options="overlay.data" :multiple="true" optionLabel="desc"
                            @change="listChange">
                            <template #option="slotProps">
                                <div class="indicator-item">
                                    <p-checkbox :id="slotProps.option.chartId" name="overlay" :value="slotProps.option"
                                        v-model="selectedOverlay" @change="onChangeOverlay(slotProps.option)" />
                                    {{ slotProps.option.desc }}

                                </div>
                            </template>
                        </p-listbox>
                    </p-accordiontab>
                </p-accordion>

            </div>
            <div style="flex: 1;" class="content-indicator">
                <IndicatorDetailBox :selectedIndicator="latestSelectedOverlayObj" @updateProp="updateProp"
                    @propChange="propChange" />
            </div>
        </div>

    </p-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { chartEvents } from '../../../chartEvents';
import { handleButtonClick } from '../../../handleRestrictFeature';
import Overlays from '../../../overlays';
import IndicatorDetailBox from './IndicatorDetailBox.vue';

export default {
    name: "IndicatorModal",
    props: {
        visible: {
            type: Boolean,
            required: true,
        },
    },
    components: { IndicatorDetailBox },
    data() {
        return {
            loading: false,
            selectedOverlay: null,
            latestSelectedOverlay: [],
            latestSelectedOverlayObj: null,
            activeTab: 0,
            overlayTitleList: {
                trendCharts: 'Trend',
                momentumCharts: 'Momentum',
                volumeCharts: 'Volume',
                volatilityCharts: 'Volatility',
                plusCharts: 'Other',
            },
            changesNotSaved: false,
            propUpdates: [],
            filteredtext: '',
            filterOverlayList: []
        }
    },
    computed: {
        ...mapGetters([
            'trendCharts',
            'momentumCharts',
            'volatilityCharts',
            'volumeCharts',
            'plusCharts',
            'allChartOverlays',
            'userChartlist',
            'userData',
            'chartToggleVolume'
        ]),
        overlayList() {
            let allOverlayList = []
            Object.keys(this.overlayTitleList).map((key) => {
                allOverlayList.push({
                    title: this.overlayTitleList[key],
                    data: this[key].data
                })
            })

            return allOverlayList
        },

    },
    mounted() {

    },
    watch: {
        allChartOverlays: {
            handler(newVal, o) {
                if (newVal) {
                    this.selectedOverlay = newVal.filter(x => x.selected == true)
                    this.latestSelectedOverlay = newVal.filter(x => x.selected == true)
                }
            },
            deep: true,
            immediate: true,
        },
        overlayList: {
            handler(newVal) {
                this.filterOverlayList = [...newVal];
            },
            immediate: true,
        },

    },
    methods: {
        handleButtonClick,
        ...mapActions([
            'saveMultiChartSettings',
            'saveUserSettings'
        ]),
        onChangeOverlay(option) {
            const index = this.selectedOverlay.indexOf(option);
            if (index === -1) {
                this.selectedOverlay.push(option);
            } else {
                this.selectedOverlay.splice(index, 1);
            }
        },
        listChange(event) {
            if (event.value?.length > this.latestSelectedOverlay?.length) {
                this.latestSelectedOverlayObj = event.value[event.value.length - 1]
            }
            else {
                this.latestSelectedOverlayObj = null
            }
            this.latestSelectedOverlay = event.value
        },
        async OnConfirm() {
            let overlay_tv = Object.values(Overlays)
            const newArr = this.selectedOverlay.map(x => x.name)
            const oldArr = this.allChartOverlays.filter(x => x.selected == true).map(x => x.name)
            let editedOverlay = []
            newArr.map((x) => {
                if (!oldArr.includes(x)) {

                    let config = this.allChartOverlays.find((n) => n.name === x)
                    let overlay = overlay_tv.find(
                        (n) => n.name.toUpperCase() === x.toUpperCase()
                    )
                    if (overlay && overlay.methods.meta_info && config) {

                        editedOverlay.push({
                            data: config,
                            overlay: overlay,
                            isAdd: true,
                            isRemove: false
                        })
                    }
                }
            })
            oldArr.map((x) => {
                if (!newArr.includes(x)) {

                    let config = this.allChartOverlays.find((n) => n.name === x)
                    let overlay = overlay_tv.find(
                        (n) => n.name.toUpperCase() === x.toUpperCase()
                    )
                    if (overlay && overlay.methods.meta_info && config) {

                        editedOverlay.push({
                            data: config,
                            overlay: overlay,
                            isAdd: false,
                            isRemove: true
                        })
                    }
                }
            })
            let chartList = this.userChartlist
            if (editedOverlay.length > 0) {

                let editedList = editedOverlay.map((item) => {
                    return {
                        chartName: item.data.chartId,
                        isAdd: item.isAdd,
                        isRemove: item.isRemove
                    }
                })

                if (
                    await this.saveMultiChartSettings(editedList)
                ) {
                    editedOverlay.map(async (overlayItem, index) => {
                        let { name, chartId, tool_type } = overlayItem.data
                        const userSettings = overlayItem.data.getData()

                        if (overlayItem.isRemove) {
                            let ind = chartList.indexOf(chartId)
                            if (ind > -1) {
                                chartList.splice(ind, 1)
                                chartEvents.$emit('remove_overlay_new', overlayItem.overlay, name)
                                if (name == "Ichi") {
                                    chartEvents.$emit('removeIchiData')
                                }

                            }
                        } else {
                            let isArray = Array.isArray(userSettings)
                            if (isArray) {
                                for (const settings of userSettings) {
                                    settings['chartId'] = chartId
                                    settings['tool_type'] = tool_type
                                }
                            } else {
                                userSettings['chartId'] = chartId
                                userSettings['tool_type'] = tool_type
                            }

                            let grid = {}
                            if (overlayItem.data.childRef === 'VolumeOVB') {
                                if (!this.chartToggleVolume) {
                                    await this.$store.dispatch('toggleVolumeChart')
                                }
                                let gridMap = this.$store.state?.dc?.dcgl
                                const volumeKey = 'offchart.VolumeOBV0'
                                if (gridMap && volumeKey in gridMap) {
                                    let gridID = gridMap[volumeKey].split('_')[0].slice(1)
                                    grid.id = Number(gridID)
                                } else {
                                    return
                                }


                            }
                            chartList.push(chartId)
                            chartEvents.$emit(
                                'add_overlay_new',
                                overlayItem.overlay,
                                name,
                                userSettings,
                                false,
                                true,
                                grid
                            )
                        }
                    })
                }
                this.$store.commit('SET_USER_CHART_LIST', { data: chartList })
            }
            if (this.changesNotSaved) {
                let res = await this.saveUserSettings()
                if (res) {
                    this.$store.commit('USER_SET_UPDATE_CHARTS', this.propUpdates)
                    this.propUpdates = []
                }
                this.changesNotSaved = false

            }
            
            this.onClose()
        },
        onReset() {
            this.filterOverlayList = this.overlayList
            this.filteredtext = ''
            this.selectedOverlay = this.allChartOverlays.filter(x => x.selected == true)
            this.latestSelectedOverlay = this.allChartOverlays.filter(x => x.selected == true)
            this.latestSelectedOverlayObj = null
        },
        updateProp(id) {
            if (!this.propUpdates.includes(id)) {
                this.changesNotSaved = true
                this.propUpdates.push(id)
            }
        },
        propChange() {
            this.changesNotSaved = true
        },
        onClose() {
            this.selectedOverlay = this.allChartOverlays.filter(x => x.selected == true)
            this.latestSelectedOverlayObj = null
            this.latestSelectedOverlay = this.allChartOverlays.filter(x => x.selected == true)
            this.propUpdates = []
            this.changesNotSaved = false
            this.$emit('toggleModal', false)
        },
        keyUpSearch(e) {
            const ignoredKeyCodes = [13, 35, 36, 37, 38, 39, 40, 16, 17]
            const { keyCode, target } = e
            const searchText = target.value.trim()
            if (ignoredKeyCodes.indexOf(keyCode) > -1) {
                return
            }
            if (searchText !== '') {

                let result = this.overlayList.map((item) => {
                    return {
                        ...item,
                        data: item.data.filter(x => x.desc.toLowerCase().includes(searchText.toLowerCase()))
                    }
                }).filter(x => x.data.length > 0)
                this.filterOverlayList = result
            }
            else {
                this.filterOverlayList = this.overlayList
            }

        }
    }

};
</script>

<style scoped>
/* Add custom styles if needed */
</style>